import { CircleNotch } from '@phosphor-icons/react'
import { twMerge } from 'tailwind-merge'

function Spinner({ text, size = 24, className = '' }: { text?: string; size?: number; className?: string }) {
  return (
    <span className={twMerge('flex items-center space-x-1', className)}>
      <CircleNotch size={size} className="shrink-0 animate-spin rounded-full" />
      {text && <span>{text}</span>}
    </span>
  )
}

export default Spinner
