'use client'
import { signIn, signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { twMerge } from 'tailwind-merge'
import { useDisconnect } from 'wagmi'
import PrimaryButton from './PrimaryButton'
import Spinner from './Spinner'

const buttonClasses = 'px-24 py-2 md:order-2 md:py-1'
const shadowClass = 'drop-shadow-[0_0_4px_rgba(var(--color-background))'

function LoadingSessionButton({ transparent }: { transparent?: boolean }) {
  return (
    <PrimaryButton
      onClick={() => {
        close()
        signIn()
      }}
      className={twMerge(buttonClasses, !transparent && shadowClass)}
      disabled
    >
      <Spinner text="Loading" />
    </PrimaryButton>
  )
}

function SignOutButton({ transparent }: { transparent?: boolean }) {
  const { disconnect } = useDisconnect()

  return (
    <PrimaryButton
      onClick={() => {
        disconnect()
        signOut()
      }}
      className={twMerge(buttonClasses, !transparent && shadowClass)}
    >
      Sign Out
    </PrimaryButton>
  )
}

function MySensayButton({ transparent }: { transparent?: boolean }) {
  return (
    <Link href="/me">
      <PrimaryButton className={twMerge(buttonClasses, !transparent && shadowClass)}>My Sensay</PrimaryButton>
    </Link>
  )
}

function SignInButton({ transparent }: { transparent?: boolean }) {
  return (
    <Link href="/sign-in">
      <PrimaryButton className={twMerge(buttonClasses, !transparent && shadowClass)}>Sign In</PrimaryButton>
    </Link>
  )
}

function UserProfileButton({ transparent }: { transparent?: boolean }) {
  const session = useSession()
  const pathname = usePathname()

  const { status } = session
  const isLoadingSession = status === 'loading'
  const isLoggedIn = status === 'authenticated'

  const hideSignInPaths = ['/sign-in']
  const signoutButtonPaths = ['/me']

  const hideSignIn = hideSignInPaths.includes(pathname)
  const showSignoutButton = isLoggedIn && signoutButtonPaths.includes(pathname)

  if (isLoadingSession && !hideSignIn) {
    return <LoadingSessionButton transparent={transparent} />
  }

  if (isLoggedIn) {
    if (showSignoutButton) {
      return <SignOutButton transparent={transparent} />
    }

    return <MySensayButton transparent={transparent} />
  }

  if (!hideSignIn) {
    return <SignInButton transparent={transparent} />
  }

  return null
}

export default UserProfileButton
